import { Formik, Form as FormikForm, FormikValues, Field } from "formik";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
// import { setLoading, loaderData } from "../../../../store/slices/loaderSlice";
import { postData } from "../../../../Services";
import toast from "react-hot-toast";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Error from "../../../atoms/Error";
import { expitationTime } from "../../../../helpers/expirationTime";
import TextInput from "../../../atoms/TextInput";
import { OtpInput } from "mui-otp-input";
import OTP from "../../../atoms/OtpInput";

const Form = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate(-1);
  const isLoading = false;
  const [resendLoading, setResendLoading] = useState(false);
  const [cookie, setCookie] = useCookies(["uid", "role", "un", "t"]);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);

  const storedCountdownStartTime = localStorage.getItem("countdownStartTime");
  const initialResendTimer = storedCountdownStartTime
    ? Number(storedCountdownStartTime)
    : 30;

  const [resendTimer, setResendTimer] = useState(initialResendTimer);
  const [showResend, setShowResend] = useState(false);

  const startResendTimer = () => {
    setResendTimer(30);
    setShowResend(false);
  };

  const handleResendCompleted = () => {
    startResendTimer();
  };

  const handleResendClick = async () => {
    try {
      setResendLoading(true);
      // dispatch(setLoading(true));
      const { data: resultData, isLoading: resendLoading } = await postData({
        endpoint: "adminAuth/resendOTP",
        data: {},
        params: { user_id: cookie.uid },
      });

      if (resultData.status) {
        // dispatch(setLoading(false));
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
        handleResendCompleted();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setResendLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevResendTimer) => prevResendTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [resendTimer]);

  useEffect(() => {
    localStorage.setItem("countdownStartTime", resendTimer.toString());
  }, [resendTimer]);

  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = async (values) => {
    console.log("values", typeof otp);

    if (otp.length < 6) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    const setCookies = (companyId, token, name, role, roleGroup) => {
      setCookie("cid", companyId, { path: "/", expires: expitationTime() });
      setCookie("t", token, { path: "/", expires: expitationTime() });
      setCookie("un", name, { path: "/", expires: expitationTime() });
      setCookie("role", role, { path: "/", expires: expitationTime() });
      setCookie("roleGroup", roleGroup, {
        path: "/",
        expires: expitationTime(),
      });
    };

    try {
      // dispatch(setLoading(true));
      const dataToSend = { otp: otp, user_id: cookie.uid };
      const { data: resultData } = await postData({
        endpoint: "adminAuth/verifyOTP",
        data: {},
        params: dataToSend,
      });

      // dispatch(setLoading(false));

      if (resultData.status) {
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });

        navigate("/dashboard");

        setCookies(
          resultData.data?.user_id,
          resultData?.data?.token,
          btoa(resultData?.data?.user_name),
          btoa(resultData?.data?.role_id),
          btoa(resultData?.data?.role_group_id)
        );
        handleResendCompleted();
      } else {
        toast.error(resultData.message, { duration: 1000, style: errorStyles });
      }
    } catch (error) {
      // dispatch(setLoading(false));
      console.error(error);
      toast.error(error?.error?.response?.data?.message, {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="form-group mt-6">
        <label htmlFor="Otp" className="label-sm">
          Verification Code
        </label>
        <OTP
          separator={<span></span>}
          value={otp}
          onChange={setOtp}
          length={6}
        />
      </div>

      {error && <Error error="OTP must be 6 digits long" />}

      <button
        className="btn btn-primary w-100 mt-4 cursor-pointer btn-xl"
        disabled={isLoading && resendLoading}
        type="submit"
        onClick={handleSubmit}
      >
        Verify{" "}
        {isLoading && resendLoading && (
          <span
            className="spinner-border spinner-border-sm ms-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
      </button>

      <div className={`d-flex gap-4 mt-4`}>
        <button
          className="btn btn-outline-dark w-50 cursor-pointer btn-xl"
          onClick={() => navigate(-1)}
          type="button"
        >
          Back
        </button>
        {resendTimer === 0 || showResend ? (
          <button
            className={`btn btn-outline-primary w-50 cursor-pointer btn-xl`}
            onClick={handleResendClick}
            type="button"
          >
            Resend
            {resendLoading && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        ) : (
          <button
            className={`btn-outline-grey w-50 btn-xl ${
              resendLoading && "pointer-none"
            }`}
            onClick={handleResendClick}
            type="button"
            disabled
          >
            {formatTimer(resendTimer)}
          </button>
        )}
      </div>
    </>
  );
};

export default Form;
