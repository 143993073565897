import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import InfoCard from "../../../atoms/InfoCard";

// import Business from "../../../icons/Business";
// import Payment from "../../../icons/Payment";
// import Email from "../../../icons/Email";
// import Message from "../../../icons/Message";
import { Heading } from "../../../atoms/Heading";
import EyeIcon from "../../../icons/eye.icon";
import Counterparty from "../../../icons/Counterparty";
import { useCookies } from "react-cookie";
import { getData } from "../../../../Services";

const Home = () => {
  const [cookies] = useCookies(["t"]);
  const [businessData, setBusinessData] = useState();
  const GetbusinessData = async () => {
    try {
      const res = await getData({
        endpoint: "business/homecounts",
        token: cookies.t,
      });
      setBusinessData(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetbusinessData();
  }, []);
  return (
    <>
      <Heading className="HeadingStyle mb-0">Home</Heading>
      <div className={`${styles.info_card_container}`}>
        <InfoCard
          color="#B1E5FC"
          title="Total Businesses"
          data={businessData?.business || 0}
          svgIcon={<Counterparty />}
          isSvg

          // data={data?.total_company || 0}
        />
        <InfoCard
          color="#FFD88D"
          title="Total Users"
          data={businessData?.users || 0}
          isSvg
          svgIcon={<EyeIcon />}

          // data={data?.total_company || 0}
        />
      </div>
    </>
  );
};

export default Home;
