import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
// import PageHeader from "../../../atoms/PageHeader";
// import BackBtn from "../../../../atoms/BackBtn";
// import PageCard from "../../../../atoms/PageCard";
// import   from "../../../../atoms/ ";
import Error from "../../../../atoms/Error";
import MyDropdown from "../../../../atoms/MyDropdown/index";
import MyInput from "../../../../atoms/MyInput/index";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import { businessTypes } from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import { replaceSpacesInImageUrl } from "../../../../../helpers/replaceSpaceInImageUrl";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { useCookies } from "react-cookie";
import {
  getData,
  postFormData,
  patchFormData,
  postData,
  patchData,
} from "../../../../../Services/index";
import dayjs from "dayjs";
import { statusData } from "../../../../../helpers/staticData";
// import { checkEmailExists } from "../../../../../services/basicGets";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { Heading } from "../../../../atoms/Heading";
import MyTextArea from "../../../../atoms/MyTextArea";
import Breadcrumb from "../../../../atoms/Breadcrumb";

const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};

const Add = ({ type = "add" }) => {
  const { id } = useParams();

  const [cookies] = useCookies(["t"]);
  const [plans, setPlans] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [emailError, setEmailError] = useState(null);
  const [domainError, setDomainError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "business/getBusinessByID",
        token: cookies.t,
        params: { id },
      });
      if (res && res?.data) {
        setInitialValues(res?.data);
      }
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const initialData = {
    name: initialValues?.name || null,
    sub_domain: initialValues?.sub_domain || null,
    email: initialValues?.email || null,
    business_email: initialValues?.business_email || null,
    business_type: initialValues?.business_type || null,
    official_address: initialValues?.official_address || null,
    registered_address: initialValues?.registered_address || null,
    primary_cc: initialValues?.primary_cc || "+91",
    alt_cc: initialValues?.alt_cc || "+91",
    primary_number: initialValues?.primary_number || null,
    alt_number: initialValues?.alt_number || null,
    contact_cc: initialValues?.contact_cc || "+91",
    gst: initialValues?.gst || null,
    contact_number: initialValues?.contact_number || null,
    contact_name: initialValues?.contact_name || null,
    status: initialValues?.status || false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Business Name is required"),
    sub_domain: Yup.string().required("SubDomain Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    business_email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    business_type: Yup.string().required("Business Type is required"),
    primary_cc: Yup.string().required("Country code is required"),
    alt_cc: Yup.string().required("Country code is required"),
    contact_cc: Yup.string().required("Country code is required"),
    primary_number: Yup.string().when("primary_cc", {
      is: "+91", // Condition: when primary_cc is '+91'
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Primary Phone Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Primary Phone Number is required"),
    }),
    alt_number: Yup.string()
      .nullable()
      .when("alt_cc", {
        is: "+91", // Condition: when primary_cc is '+91'
        then: () =>
          Yup.string()
            .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
            .nullable(),
        otherwise: () =>
          Yup.string()
            .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
            .nullable(),
      }),
    gst: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
        "Invalid GST number"
      )
      .nullable(),
    official_address: Yup.string().required("Official Address is required"),
    registered_address: Yup.string().required("Registered Address is required"),
    contact_name: Yup.string().required("Contact Person Name is required"),
    contact_number: Yup.string().when("contact_cc", {
      is: "+91", // Condition: when primary_cc is '+91'
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Contact Person Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Contact Person Number is required"),
    }),
    // end_date: Yup.date()
    //   .required("Subscription End Date is required")
    //   .min(Yup.ref("start_date"), "End Date cannot be before Start Date"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "business/create",
        token: cookies.t,
        data: {
          ...values,
          contact_name: capitalizeLetter(values.contact_name),
          name: capitalizeLetter(values.name),
        },
      });
      if (res) {
        toast.success("Business Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      contact_name: capitalizeLetter(values.contact_name),
      name: capitalizeLetter(values.name),
    };

    try {
      const res = await patchData({
        endpoint: "business/update",
        token: cookies.t,
        data: data,
        params: { id: id },
      });
      if (res) {
        toast.success("Business Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "business/checkEmail",
      token: cookies.t,
      params: { email: newVal },
    });
    if (response.status && response.data) {
      response.message[0] === "U"
        ? setEmailError(
            "User Is Mapped with Another Business,Use Different Email"
          )
        : setEmailError("Email Already Exists");
    } else {
      setEmailError(null);
    }
  };

  const checkDomain = async (newVal) => {
    const response = await getData({
      endpoint: "business/checkDomain",
      token: cookies.t,
      params: { sub_domain: newVal },
    });
    if (response.status && response.data) {
      setDomainError("Sub-Domain Already Exists");
    } else {
      setDomainError(null);
    }
  };

  function extractCountryCode(str) {
    const match = str.match(/\(\+(\d+)\)/);
    return match ? `+${match[1]}` : null;
  }

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        validateForm,
      }) => (
        <>
          <Breadcrumb
            labels={[
              "Business",
              `${type === "add" ? "Add Business" : "Edit Business"}`,
            ]}
          />
          <Heading className="h5 mb-3 mt-3">
            {type === "add" ? "Add Business" : "Edit Business"}
          </Heading>
          <hr />

          {/* <div
            className="d-flex flex-wrap w-100 mb-4"
            style={{ columnGap: "1.36%", rowGap: "16px" }}
          >
            <div className="col-md">
              <label htmlFor="">Logo</label>
              <ImageSelector
                style={ImageSelectorStyle}
                type="Company"
                handleFile={(file) => {
                  setFieldValue("logo", file);
                  setFieldValue("remove_logo", false);
                }}
                aspect={2}
                widthLimit={600}
                heightLimit={400}
                selectedImage={replaceSpacesInImageUrl(initialData?.logo)}
                description="Logo image should be 600X400px and size is 1MB"
                onRemove={() => {
                  setFieldValue("remove_logo", true);
                }}
              />
            </div>

            <div className="col-md"></div>
          </div> */}

          <div
            className="d-flex flex-wrap w-100 mb-4"
            style={{ columnGap: "1.36%", rowGap: "16px" }}
          >
            <MyInput
              type="text"
              name="name"
              customholder="Business Name*"
              width="32.426%"
            />

            <MyInput
              type="text"
              name="business_email"
              customholder="Business Email*"
              width="32.426%"
            />

            <MyDropdown
              placeholder="Select Business Type*"
              data={businessTypes}
              onChange={(option) => {
                setFieldValue("business_type", option);
              }}
              handleBlur={(e) => {
                setFieldTouched("business_type", true);
                handleBlur(e);
              }}
              selectedValue={values?.business_type}
              name="business_type"
              touched={touched.business_type}
              errors={errors.business_type}
              width="32.426%"
            />

            <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
              <MyDropdown
                data={countryCodeData}
                selectedValue={values.primary_cc}
                name="primary_cc"
                width="23%"
                type="Country"
                onChange={(option) => {
                  setFieldValue("primary_cc", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("primary_cc", true);
                  handleBlur(e);
                }}
              />

              <MyInput
                type="text"
                name="primary_number"
                customholder="Enter Primary Phone Number*"
                width="73%"
                onChange={(e) => {
                  let numericInput = e.target.value.replace(/[^0-9]/g, "");
                  if (values.primary_cc === "+91") {
                    // Enforce a maximum length of 10 digits
                    if (numericInput.length > 10) {
                      numericInput = numericInput.slice(0, 10);
                    }
                    setFieldValue("primary_number", numericInput);
                  } else {
                    setFieldValue("primary_number", numericInput);
                  }
                }}
              />
            </div>

            <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
              {/* <MyInput type="text" name="alt_cc" width="23%" disabled /> */}
              <MyDropdown
                data={countryCodeData}
                selectedValue={values.alt_cc}
                name="alt_cc"
                width="23%"
                type="Country"
                onChange={(option) => {
                  setFieldValue("alt_cc", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("alt_cc", true);
                  handleBlur(e);
                }}
              />
              <MyInput
                type="text"
                name="alt_number"
                customholder="Enter Alternate Phone Number"
                width="73%"
                onChange={(e) => {
                  let numericInput = e.target.value.replace(/[^0-9]/g, "");
                  if (values.alt_cc === "+91") {
                    // Enforce a maximum length of 10 digits
                    if (numericInput.length > 10) {
                      numericInput = numericInput.slice(0, 10);
                    }
                    setFieldValue("alt_number", numericInput);
                  } else {
                    setFieldValue("alt_number", numericInput);
                  }
                }}
              />
            </div>

            <MyInput
              type="text"
              name="gst"
              width="32.426%"
              customholder="Enter GST No."
            />

            <MyInput
              type="text"
              name="sub_domain"
              width="32.426%"
              customholder="Enter Sub Domain*"
              customBlur={(e) => {
                if (initialValues.sub_domain !== values.sub_domain) {
                  checkDomain(values.sub_domain);
                }
              }}
              blurError={domainError}
            />

            <MyTextArea
              as="textarea"
              customholder="Enter Official Address*"
              name="official_address"
            />
            <MyTextArea
              as="textarea"
              customholder="Enter Registered Address*"
              name="registered_address"
            />
          </div>

          <Heading className="h5 mb-3 mt-3">Contact Details</Heading>
          <hr />

          <div
            className="d-flex flex-wrap w-100"
            style={{ columnGap: "1.36%", rowGap: "16px" }}
          >
            <MyInput
              type="text"
              name="contact_name"
              width="32.426%"
              customholder="Enter Contact Person Name*"
            />
            <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
              <MyDropdown
                data={countryCodeData}
                selectedValue={values.contact_cc}
                name="contact_cc"
                width="23%"
                type="Country"
                onChange={(option) => {
                  setFieldValue("contact_cc", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("contact_cc", true);
                  handleBlur(e);
                }}
              />

              <MyInput
                type="text"
                name="contact_number"
                customholder="Enter Contact Number*"
                width="73%"
                onChange={(e) => {
                  let numericInput = e.target.value.replace(/[^0-9]/g, "");
                  if (values.contact_cc === "+91") {
                    // Enforce a maximum length of 10 digits
                    if (numericInput.length > 10) {
                      numericInput = numericInput.slice(0, 10);
                    }
                    setFieldValue("contact_number", numericInput);
                  } else {
                    setFieldValue("contact_number", numericInput);
                  }
                }}
              />
            </div>
            <MyInput
              type="text"
              name="email"
              customholder="Email*"
              width="32.426%"
              customBlur={(e) => {
                if (initialValues.email !== values.email) {
                  checkEmail(values.email);
                }
              }}
              blurError={emailError}
            />

            {type === "edit" && (
              <MyDropdown
                placeholder="Status"
                data={statusData}
                onChange={(option) => {
                  setFieldValue("status", option === "true" ? true : false);
                }}
                handleBlur={(e) => {
                  setFieldTouched("status", true);
                  handleBlur(e);
                }}
                selectedValue={values?.status === true ? "true" : "false"}
                name="status"
                touched={touched.status}
                errors={errors.status}
                width="32.426%"
              />
            )}
          </div>

          <div className="d-flex gap-3 mt-4">
            {type === "add" ? (
              <CustomButton
                iconRequired={false}
                type="btn-primary"
                buttonType="submit"
                handleClick={() => handleSubmit(values)}
                disabled={
                  !isValid ||
                  !dirty ||
                  isSaving ||
                  emailError !== null ||
                  domainError !== null
                }
              >
                Create Business
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            ) : (
              <CustomButton
                iconRequired={false}
                type="btn-primary"
                buttonType="submit"
                handleClick={() => handleEdit(values)}
                disabled={!isValid || isSaving || emailError || domainError}
              >
                Edit Business
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            )}

            <CustomButton
              text="Cancel"
              iconRequired={false}
              handleClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </>
      )}
    </Formik>
  );
};

export default Add;
