const CompanyLogo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 250 40"
      style={{
        enableBackground: "new 0 0 250 40",
        scale: "0.85",
        transformOrigin: "left",
      }}
    >
      <style type="text/css">
        {`.st0{fill:#FFFFFF;}
          .st1{fill:#FFFFFF;}`}
      </style>
      <g>
        <g>
          <path
            class="st0"
            d="M73.4,39.5H42.1v-7.6h31.4V39.5z M68,15.2H48.8v7.6H68V15.2z M73.4,0.6H55.6v7.6h17.8V0.6z"
          />
          <path
            class="st1"
            d="M18.3,30.3l5.6,9.2L46.3,0.6H35.8L18.3,30.3z M36.5,30.3l5.6,9.2L64.5,0.6H54.1L36.5,30.3z M32.3,23.2    l4.5,7.6l3.9-6.1l-5.2-8.8L32.3,23.2z"
          />
          <g>
            <g>
              <polygon class="st0" points="0.9,0.5 28.7,0.5 14.8,24.4     " />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M91.1,0.5l4,4v5.3h-5.2V5.8h-6.5v16.9h6.5v-4.1h5.2v5.3l-4,4h-9l-4-4V4.5l4-4H91.1z"
            />
            <path
              class="st1"
              d="M109.2,0.5h5.3V28h-5.3V16.8h-5.8V28h-5.3V0.5h5.3v11.1h5.8V0.5z"
            />
            <path
              class="st1"
              d="M152.9,0.5l4,4v8.3l-1.4,1.4l1.4,1.4V28h-5.2V17.5h-6.5V28h-5.3V0.5H152.9z M151.7,5.8h-6.5v6.5h6.5V5.8z"
            />
            <path
              class="st1"
              d="M175.3,0.5v5.3h-5.1V28H165V5.8h-5.1V0.5H175.3z"
            />
            <path
              class="st1"
              d="M192.2,0.5v5.3h-8.7v5.8h6.9v5.2h-6.9v5.8h8.7V28h-14V0.5H192.2z"
            />
            <path
              class="st1"
              d="M208.1,0.5l4,4v8.3l-1.4,1.4l1.4,1.4V28h-5.2V17.5h-6.5V28h-5.3V0.5H208.1z M206.9,5.8h-6.5v6.5h6.5V5.8z"
            />
            <path
              class="st1"
              d="M229.1,0.5v5.3h-8.7v5.8h6.9v5.2h-6.9v5.8h8.7V28h-14V0.5H229.1z"
            />
            <path
              class="st1"
              d="M245,0.5l4,4v19.4l-4,4h-13V0.5H245z M243.8,5.8h-6.5v16.9h6.5V5.8z"
            />
            <g>
              <path
                class="st1"
                d="M82.6,30.2l1.4,1.4v1.8h-1.8V32H80v2h2.6l0.9,0.9l0,0l0.5,0.5v2.8l-1.4,1.4h-3l-1.4-1.4v-1.8H80v1.4h2.2v-2      h-2.7l-1.3-1.4v-2.8l1.4-1.4H82.6z"
              />
              <path
                class="st1"
                d="M92.8,30.2l1.4,1.4v1.8h-1.8V32h-2.2v5.7h2.2v-1.4h1.8v1.8l-1.4,1.4h-3l-1.4-1.4v-6.6l1.4-1.4H92.8z"
              />
              <path
                class="st1"
                d="M100,39.5h-1.8l2.5-9.3h1.8l2.4,9.3h-1.8l-0.4-1.6h-2.3L100,39.5z M101.6,33.6l-0.7,2.5h1.3L101.6,33.6z"
              />
              <path class="st1" d="M111.1,37.7h2.9v1.8h-4.7v-9.3h1.8V37.7z" />
              <path
                class="st1"
                d="M123.1,30.2V32h-2.9v2h2.4v1.8h-2.4v2h2.9v1.8h-4.7v-9.3H123.1z"
              />
              <path
                class="st1"
                d="M136.7,30.2h1.8v7.9l-1.4,1.4h-3l-1.4-1.4v-7.9h1.8v7.5h2.2V30.2z"
              />
              <path
                class="st1"
                d="M147.3,30.2l1.4,1.4v3l-1.4,1.4h-2.6v3.5h-1.8v-9.3H147.3z M146.9,32h-2.2v2.2h2.2V32z"
              />
              <path
                class="st1"
                d="M163.5,30.2V32h-1.7v7.5H160V32h-1.7v-1.8H163.5z"
              />
              <path
                class="st1"
                d="M172.4,30.2l1.4,1.4v6.6l-1.4,1.4h-3l-1.4-1.4v-6.6l1.4-1.4H172.4z M171.9,32h-2.2v5.7h2.2V32z"
              />
              <path
                class="st1"
                d="M187.8,30.2l1.4,1.4v1.8h-1.8V32h-2.2v2h2.6l0.9,0.9l0,0l0.5,0.5v2.8l-1.4,1.4h-3l-1.4-1.4v-1.8h1.8v1.4      h2.2v-2h-2.7l-1.3-1.4v-2.8l1.4-1.4H187.8z"
              />
              <path
                class="st1"
                d="M197.5,30.2h1.8v7.9l-1.4,1.4h-3l-1.4-1.4v-7.9h1.8v7.5h2.2V30.2z"
              />
              <path
                class="st1"
                d="M208.1,30.2l1.4,1.4v1.8h-1.8V32h-2.2v5.7h2.2v-1.4h1.8v1.8l-1.4,1.4h-3l-1.4-1.4v-6.6l1.4-1.4H208.1z"
              />
              <path
                class="st1"
                d="M218.2,30.2l1.4,1.4v1.8h-1.8V32h-2.2v5.7h2.2v-1.4h1.8v1.8l-1.4,1.4h-3l-1.4-1.4v-6.6l1.4-1.4H218.2z"
              />
              <path
                class="st1"
                d="M228.7,30.2V32h-2.9v2h2.4v1.8h-2.4v2h2.9v1.8H224v-9.3H228.7z"
              />
              <path
                class="st1"
                d="M237.5,30.2l1.4,1.4v1.8h-1.8V32h-2.2v2h2.6l0.9,0.9l0,0l0.5,0.5v2.8l-1.4,1.4h-3l-1.4-1.4v-1.8h1.8v1.4      h2.2v-2h-2.7l-1.3-1.4v-2.8l1.4-1.4H237.5z"
              />
              <path
                class="st1"
                d="M247.7,30.2l1.4,1.4v1.8h-1.8V32h-2.2v2h2.6l0.9,0.9l0,0l0.5,0.5v2.8l-1.4,1.4h-3l-1.4-1.4v-1.8h1.8v1.4      h2.2v-2h-2.7l-1.3-1.4v-2.8l1.4-1.4H247.7z"
              />
            </g>
            <polygon
              class="st1"
              points="121,18.1 117.4,28 122.6,28 124.8,19.8 127.2,19.8 129.7,19.8 131.8,28 137,28 133.5,18.1      127.2,14.6    "
            />
          </g>
        </g>
        <polygon
          class="st0"
          points="121.2,16.4 127.2,0.5 133.3,16.4 127.2,12.7  "
        />
        <polygon class="st1" points="127.2,0.5 133.3,16.4 127.2,12.7  " />
      </g>
    </svg>
  );
};
export default CompanyLogo;
