import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "../../../assets/images/login3.jpeg";
import style from "./authlayout.module.scss";
import { authRoutes } from "../../../routes/routes";

const AuthLayout = ({ children }) => {
  return (
    <div className={style.auth_screen}>
      <div className={style.auth_image}>
        <img
          src={Logo}
          className={style.logo_image}
          alt="company-logo"
          loading="eager"
        />
      </div>
      <div className={style.auth_content}>
        <div className={style.auth_child}>
          <Outlet />
          {authRoutes}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
