import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../Services";
import View from "../../../icons/View";
import EditBlue from "../../../icons/EditBlue";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import Delete from "../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import Edit from "../../../icons/EditBlue";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import FilterComponent from "../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../utils/staticData";
import EyeIcon from "../../../icons/eye.icon";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard";

const Business = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const [businessData, setBusinessData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});

  const menu = [
    {
      key: "view",
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`view/${selectedRow?._id}`);
      },
    },
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const GetbusinessData = async () => {
    try {
      const res = await getData({
        endpoint: "business",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          rate_type: filters?.["Rate Type"],
        },
      });
      setBusinessData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetbusinessData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Business Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "primary_phone",
      headerName: "Primary Phone No.",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.primary_number && params.row.primary_cc}{" "}
            {params.row.primary_number}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "contact_name",
      headerName: "Contact Person",
      sortable: true,
      flex: 1,
    },
    {
      field: "is_active",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.status ? (
              <span className="text-success fw-bold">Active</span>
            ) : (
              <span className="text-error fw-bold">Inactive</span>
            )}
          </>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {/* <div className="table-icons-container d-flex gap-1">
              <div onClick={() => navigate(`view/${params.row._id}`)}>
                <View />
              </div>
              <div onClick={() => navigate(`edit/${params.row._id}`)}>
                <Edit />
              </div>

              <div onClick={() => {}}>
                <Delete />
              </div>
            </div> */}
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </>
        );
      },
    },
  ];

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        {!!searchData ? (
          <NoContentCard title="No Record Found" type="Pricing" />
        ) : (
          <NoContentCard
            title="No Business created yet!"
            tag="Add Business"
            type="Pricing"
            classes="d-flex align-items-center"
            subtitle="Currently no business created yet click on a add business button to create business"
            handleClick={() => {
              navigate("add");
            }}
          />
        )}
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "business/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Price Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetbusinessData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    // "Rate Type": Rate_TypeData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "price/export",
      token: cookies.t,
      fileName: "Prices",
      params: {
        search: searchData,
        rate_type: filters?.["Rate Type"],
      },
    });

    if (res) {
      toast.success("Prices Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Business</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />
          {/* <CustomButton
            text="Export"
            handleClick={() => handleDownload()}
            classes={"filter-contain-btn"}
            iconRequired
            icon={<Upload />}
          />

          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          /> */}

          <CustomButton
            text="Add Business"
            type="btn-primary"
            handleClick={() => {
              navigate("add");
            }}
          />
        </div>
      </div>
      <CustomDatagrid
        rows={businessData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {businessData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={businessData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Business"
      />
    </>
  );
};
export default Business;
