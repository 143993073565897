import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { CookiesProvider } from "react-cookie";
import Errorboundary from "./Services/errorBoundarry";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <CookiesProvider>
    {/* <Provider store={store}> */}
    <Errorboundary fallback="Something Went Wrong">
      <App />
      </Errorboundary>
      {/* </Provider> */}
    </CookiesProvider>
  </BrowserRouter>
);
