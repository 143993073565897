const ShortCompantLogo = () => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 250 40"
        style={{
          enableBackground: "new 0 0 250 40",
          scale: "3",
          transformOrigin: "left",
        }}
      >
        <style type="text/css">
          {`.st0{fill:#FFFFFF;}
          .st1{fill:#FFFFFF;}`}
        </style>
        <g>
          <g>
            <path
              class="st0"
              d="M73.4,39.5H42.1v-7.6h31.4V39.5z M68,15.2H48.8v7.6H68V15.2z M73.4,0.6H55.6v7.6h17.8V0.6z"
            />
            <path
              class="st1"
              d="M18.3,30.3l5.6,9.2L46.3,0.6H35.8L18.3,30.3z M36.5,30.3l5.6,9.2L64.5,0.6H54.1L36.5,30.3z M32.3,23.2    l4.5,7.6l3.9-6.1l-5.2-8.8L32.3,23.2z"
            />
            <g>
              <g>
                <polygon class="st0" points="0.9,0.5 28.7,0.5 14.8,24.4     " />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default ShortCompantLogo;
